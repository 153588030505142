#data-grid{
  border-bottom: 1px solid rgb(225, 223, 223);
  background-color: white;
  padding: .5%;
  padding-top: 1.5%;
}
#title-Assignment{
  font-size: 19px;
  font-weight: 500;
}
#grid-item-1{
    padding-top: .5%;
    padding-left: 3%;
    margin-top: -1%;
}
#edit-btn{
    text-align: right;
    padding-right: 2%;
    color: gray;
}
#edit-btn-hover:hover{
 cursor: pointer;
}
#deleteBtn{
  padding-left: 6%;
}
#assignmentStyle{
  color: black;
  font-size: 1.5rem;
}
@media screen and (max-width:430px) {
  #edit-btn{
    text-align: left;
}  
#addAssignmentBtn{
  margin-left: 0px;
  width: 60%;
  margin-top: 4%;
  margin-bottom: 4%;
}
#endDateGrid{
  margin-left: 0px;
}
}