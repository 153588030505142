::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar width */
  height: 0px; /* Remove scrollbar height */
  background: transparent; /* Make the scrollbar background transparent */
}
.css-16hmoyr {
  /* position: relative;
  width: 0px;
  z-index: -1; */
  bottom: 52px;
  left: 57px;
}
