*{
    font-family: sans-serif;
}
.cardItem{
    margin-right: 1.5rem;
    border-radius:5px;
    padding-left: 10px;
    width: 20rem;
    background-color: #c2c2c2;
    opacity: 0.8;
    color: #FFFFF7;
}
#complete-grid :hover{
  background-color: rgb(245, 247, 248);
}
#ongoing-grid :hover{
    background-color: rgb(245, 247, 248);
}
#upcoming-grid :hover{
    background-color: rgb(245, 247, 248);
} 
@media screen and (max-width: 900px) {
    #session-container{
        margin-right: 1%;
    }   
    #exam-container{
        margin-top: 2%;
    }
}