.videoContainer {
  height: 540px;
  overflow: auto;
  position: sticky;
  top: '0';
}

.video {
  padding: 10px;
  text-align: center;
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
}
