#styleText{
margin: 0 0 0.5rem;
    font-size: 1.4rem;
    line-height: 0.9;
    font-weight: 600;
    /* color: #37373C; */
    color:black;
    font-family: NoirPro,Arial;
}
#tabTextColor{
    color: rgb(133, 149, 166);
}
#tabTextColor:focus{
    color: rgb(115, 82, 199);
border-bottom: 2px solid #37373C !important;
}