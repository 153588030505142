/* Custom CSS to change the orientation of the toolbar */
.horizontal-toolbar-wrapper .rdw-editor-toolbar {
  flex-direction: row; /* Display the toolbar buttons horizontally */
}

.horizontal-toolbar-wrapper .rdw-editor-toolbar .rdw-option-wrapper {
  margin-right: 8px; /* Adjust the margin between toolbar buttons */
}

.horizontal-toolbar-wrapper .rdw-editor-toolbar .rdw-option-wrapper:last-child {
  margin-right: 0; /* Remove margin from the last toolbar button */
}
.horizontal-toolbar {
  flex-direction: row !important; /* Display the toolbar buttons horizontally */
}
